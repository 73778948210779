<template>
    <v-container>
      <v-row>
        <v-col class="mt-4" cols="12">
          <p class="text-h5 white--text">รายการวันนี้</p>
        </v-col>
      </v-row>
      <v-row>
        <CardReport1 :number="numberChannel1" :showPercent="false" :avatar="'/icon/move-vai.jpg'" />
        <CardReport1 :number="numberChannel2" :showPercent="false" :avatar="'/icon/move-on.jpg'" />
        <CardReport1 :number="numberChannel3" :showPercent="false" :avatar="'/icon/move-pai-gun.jpg'" />
      </v-row>
      <v-row>
         <!-- Data Table -->
          <v-data-table
              dark
              v-model="selected"
              :headers="headers"
              :items-per-page="30"
              :items="data"
              :loading="isLoading"
              loading-text="กำลังโหลดข้อมูล....... กรูณารอซักครู่"
              class="elevation-1"
              style="width: 100%;border: 0.5px solid #aaaaaa;"
              show-select
              no-data-text="ไม่มีข้อมูล"
              @click:row="clickRow"
          >
              <template v-slot:top>
                  <p class="text-h6 ma-2">คำสั่งซื้อทั้งหมด  
                  <span class="yellow--text text--darken-3"> {{ String(data.length).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }} รายการ </span> </p>
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                  {{ formatTimeTable(item.createdAt) }}
              </template>
              <template v-slot:[`item.channel`]="{ item }">
                  {{ getChannelName(item.channel) }}
              </template>
              <template v-slot:[`item.totalPrice`]="{ item }">
                  <label class="yellow--text text--darken-3"> 
                      {{ Number(item.totalPrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
                  </label>
              </template>
              <template v-slot:[`item.status`]="{item}">
                  <v-chip small :color="colorStatus(item.status)">
                      <v-icon class="ml-n3" :color="colorStatus(item.status).split(' ')[0]">mdi-circle-medium</v-icon>
                      <span class="">{{ getStatusThaiName(item.status) }}</span>
                  </v-chip>
              </template>
              <template v-slot:[`item.action`]="{item}">
                <!-- Copy from views/jobs/List.vue file -->
                <v-container class="text-right">
                  <v-btn
                      v-if="item.status == 'wait_messenger'"
                      color="yellow darken-3"
                      depressed
                      @click.stop.prevent="downloadCover(item)"
                  >
                      <span class="black--text">
                          พิมพ์ใบแปะหน้า
                      </span>
                  </v-btn>

                  <!-- Staff Action -->
                  <v-btn
                      color="yellow darken-3"
                      depressed
                      v-if="role == 'staff' && item.status == 'new'"
                      @click.stop.prevent="$router.push({ name: 'JobsDetails', params: { id: item.id} })"
                  >
                  <span class="black--text">
                      ส่งขออนุมัติ
                  </span>
                  </v-btn>

                  <!-- Sr Staff Action -->
                  <v-btn
                      color="yellow darken-3"
                      depressed
                      v-if="role == 'sr_staff' && item.status == 'wait_confirm'"
                      @click.stop.prevent="$router.push({ name: 'JobsDetails', params: { id: item.id} })"
                  >
                  <span class="black--text">
                      อนุมัติ
                  </span>
                  </v-btn>

                  <!-- Approval -->
                  <v-container v-if="role == 'approval' && item.status == 'wait_confirm_with_approval'" class="text-right">
                <!-- Approval Action -->
                    <v-btn
                        color="yellow darken-3"
                        depressed
                        outlined
                    >
                      <span class="white--text" @click.stop.prevent="$refs.dialogReject.show(item.id)">
                          ไม่อนุมัติ
                      </span>
                    </v-btn>
                    <v-btn
                        class="ml-3"
                        color="yellow darken-3"
                        depressed
                        @click.stop.prevent="$refs.dialogConfirmApproval.show(item.id)"
                    >
                      <span class="black--text">
                          อนุมัติ
                      </span>
                    </v-btn>
                  </v-container>
                </v-container>
              </template>
          </v-data-table>
      </v-row>
      <alert-error :text="errorMessage" ref="alertError"></alert-error>
      <dialog-confirm 
        :title="'ยืนยันการส่งอนุมัติ'" 
        :description="'โปรดตรวจสอบข้อมูลให้ครบถ้วนก่อนกดยืนยัน'" 
        @confirm="sendApprove"
        ref="dialogConfirmApproval"/>
      <dialog-success :text="'ส่งคำขออนุมัติสำเร็จ'" @hide="hideSuccessApproval" ref="dialogSuccessApprove" />
      <dialog-reject @finishReject="finishReject" ref="dialogReject" />
    </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import CardReport1 from '../../components/CardReport1.vue';
import AlertError from '../../components/AlertError.vue';
import moment from 'moment';
import { getChannelName, getStatusThaiName, getJobsList, downloadCover, colorStatus } from '../../models/job';
import DialogConfirm from '../../components/DialogConfirm.vue';
import DialogSuccess from '../../components/DialogSuccess.vue';
import DialogReject from '../../components/DialogReject.vue';
import axios from 'axios';

export default {
  data: () => ({
    //
    singleSelect: false,
    isLoading: false,
    selected: [],
    headers: [
      { text: 'หมายเลขคำสั่งซื้อ', value: 'code', sortable: false },
      { text: 'สร้างวันที่', value: 'createdAt' },
      { text: 'มูลค่าสินค้า', value: 'totalPrice', sortable: false },
      { text: 'สถานะรายการ', value: 'status' },
      { text: 'ช่องทาง', value: 'channel' },
      { text: '', value: 'action', sortable: false }
    ],
    data: [],
    errorMessage: '',
    role: ''
  }),
  methods: {
    downloadCover,
    clickRow(e){
      this.$router.push({ name: 'JobsDetails', params: { id: e.id } });
    },
    formatTimeTable(date){
      return moment(date).format('DD-MM-YY HH:mm');
    },
    getChannelName(value) {
            return getChannelName(value);
    },
    colorStatus,
    getStatusThaiName(value){
      return getStatusThaiName(value);
    },
    async getJobData(){
      this.isLoading = true;
      this.data = [];
      try{
          const today = moment().format('DD-MM-YYYY');
          let status = 'no';
          if('staff' == this.role){
            status = '';
          }else if('sr_staff' == this.role){
            status = 'wait_confirm';
          }else if('approval' == this.role){
            status = 'wait_confirm_with_approval';
          }else if('account' == this.role){
            status = '';
          }
          const response = await getJobsList(true, '', today, today, status);
          const jobs = response.data.jobs;
          jobs.forEach(j => {
              this.data.push(j);
          });
      }catch(error) {
          console.log(error);
          this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
          this.$refs.alertError.show();
      }finally{
          this.isLoading = false;
      }
    },
    async sendApprove(id){
        let loader = this.$loading.show();
        try{
            await axios.post(process.env.VUE_APP_BASE_URL + `/jobs/${id}/approve-by-approval`);
            loader.hide();
            this.$refs.dialogSuccessApprove.show();
        }catch(error) {
            console.log(error);
            this.errorMessage = error.response?.data[0]?.msg || error.response?.data || error.message;
            this.$refs.alertError.show();
        }finally{
            loader.hide();
        }
    },
    hideSuccessApproval(){
      this.getJobData();
    },
    finishReject(){
      location.reload();
    }
  },
  async mounted(){
    this.role = this.$store.state.user.currentUser ? this.$store.state.user.currentUser.role : '';
    this.getJobData();
  },
  computed:{
    numberChannel1(){
      return this.data ? this.data.filter(j => '1' == j.channel).length : 0;
    },
    numberChannel2(){
      return this.data ? this.data.filter(j => '2' == j.channel).length : 0;
    },
    numberChannel3(){
      return this.data ? this.data.filter(j => '3' == j.channel).length : 0;
    }
  },
  watch: { // using for when Appbar not yet to get Profile
    '$store.state.user.currentUser'(value){
      this.role = value ? value.role : '';
      this.getJobData();
    }
  },
  components: {
    CardReport1,
    AlertError,
    DialogSuccess,
    DialogReject,
    DialogConfirm
  }
};
</script>
