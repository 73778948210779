<template>
    <div class="indexPage">
      <admin-home
        v-if="user.role == 'admin'"
       />
      <job-index
        v-if="-1 != ['staff', 'sr_staff', 'approval', 'account'].indexOf(user.role)"
      />
      <alert-error :text="errorMessage" ref="alertError"></alert-error>
    </div>
</template>

<script>
import AlertError from '../../components/AlertError.vue';
import AdminHome from '../../views/users/Home.vue';
import JobIndex from './JobIndex.vue';

export default {
  data: () => ({
    user: {},
    errorMessage: ''
  }),
  async mounted(){
    this.user = this.$store.state.user.currentUser || {};
  },
  watch: { // using for when Appbar not yet to get Profile
    '$store.state.user.currentUser'(value){
      this.user = value;
    }
  },
  computed: {
  },
  components: {
    AlertError,
    AdminHome,
    JobIndex
  }
};
</script>

<style scoped>
  .indexPage{

  }
</style>